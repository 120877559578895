import React from 'react';
import Layout from '../components/layout';
import 'github-markdown-css';
import { Card, Col, Row, Button } from 'react-bootstrap';
import { StaticQuery } from 'gatsby';
import { VscSymbolClass, VscSymbolEnum, VscSymbolStructure, VscSymbolEvent, VscSymbolInterface } from 'react-icons/vsc';

class ApiIndex extends React.Component {
  constructor() {
    super();
  }

  render() {
    const { all_data, version, assembly_name } = this.props.pageContext;
    const interfaceData = all_data.filter((data) => data.type === 0);
    const enumData = all_data.filter((data) => data.type === 1);
    const classData = all_data.filter((data) => data.type === 2);
    const structData = all_data.filter((data) => data.type === 3);
    const delegateData = all_data.filter((data) => data.type === 4);

    return (
      <StaticQuery query={graphql`
      query {
        allSitePage(filter: {path: {regex: "/api/"}}) {
          nodes {
            path
          }
        }
      }`} render={(query) => {
        const prefix = `/api/${assembly_name.toLowerCase()}@${version}/`;
        const allPathList = query.allSitePage.nodes.filter(({ path }) => path.includes(prefix));

        function showDataList(h3, allData, icon) {
          if (allData.length === 0) return null;
          return (
            <>
              <h4 className={'pad-noline'}>{icon} {h3}</h4>
              <Row xs={1} md={2} lg={3} style={{ padding: '0px 20px' }}>
                {allData.map((data) => {
                  const path = allPathList.find((path) => path.path.includes(prefix + data.name.toLowerCase().replaceAll('+', '-')));
                  return (
                    <Col style={{ padding: '0.5rem' }} key={data.name}>
                      <Card as={Button} href={path.path} className={'summary'} variant='outline-secondary' key={data.name}>
                        <Card.Body>
                          <Card.Title style={{ color: 'black', fontSize: 'medium' }}>{data.name.replaceAll('+', '.')}</Card.Title>
                          <Card.Text
                            style={{ fontSize: 'small' }}>{data.comment.length ? data.comment.split('\n')[0] : null}</Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  );
                })
                }
              </Row>
            </>
          );
        }

        return (
          <Layout title={`${assembly_name} ${version}`} showSide version={version} assembly_name={assembly_name}>
            <h2 className={'custom'}>
              Summary
            </h2>
            {showDataList('Class', classData, <VscSymbolClass />)}
            {showDataList('Interface', interfaceData, <VscSymbolInterface />)}
            {showDataList('Enum', enumData, <VscSymbolEnum />)}
            {showDataList('Struct', structData, <VscSymbolStructure />)}
            {showDataList('Delegate', delegateData, <VscSymbolEvent />)}
          </Layout>
        );
      }}>
      </StaticQuery>


    );
  }
}

export default ApiIndex;
